.container {
  --_product-card-height: 650px;

  /* Dev default as part of margin refactor */
  margin-top: var(--spacing-bottom);
  margin-bottom: var(--spacing-bottom);
  background-color: var(--page-theme-background-color, var(--color-bg-shade));
  color: var(--page-theme-contrast-color);

  /* TODO - this `parent>:first-child` is very hacky */
  [data-type="BlocksProductRail"]:first-child > & {
    margin-top: 130px;
  }

  @media (width >= 767px) {
    [data-first-block="true"] > & {
      margin-top: 215px;
      margin-bottom: var(--spacing-bottom);
    }
  }
}

.header {
  padding-inline: var(--spacing-sides);
}

.header-inner {
  margin-inline: auto;
  max-width: var(--max-width-base);
}

.name-kicker-wrapper {
  display: block;
  margin-bottom: 36px;

  @media (width >= 767px) {
    margin-bottom: 50px;
  }

  &[data-has-gradient] {
    --kicker-border-image: linear-gradient(
        to right,
        #732c82,
        #282874 27%,
        #7a1439 51%
      )
      1;
  }
}

[data-has-custom-theme] .kicker {
  --kicker-border-color: var(--theming-kicker-border-color);
} /* `--kicker-border-image` overwrites this */

.text {
  margin-bottom: 50px;
  text-align: center;

  @media (width >= 767px) {
    margin-bottom: 70px;
  }
}

.text--large {
  margin-bottom: var(--spacing-bottom);
}

.heading {
  margin-bottom: 20px;
  color: var(--page-theme-contrast-color, var(--color-text-heading));
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-24px);
  line-height: 1;
  font-family: var(--font-family-heading);

  @media (width >= 767px) {
    margin-bottom: 35px;
    font-size: var(--font-size-48px);
    line-height: 1.17;
  }

  &[data-is-large="true"] {
    margin-inline: auto;
    margin-bottom: 30px;
    max-width: 340px; /* This is probably far to narrow... */
    font-size: var(--font-size-40px);
    line-height: 1.17;

    @media (width >= 767px) {
      margin-inline: initial;
      margin-bottom: 35px;
      max-width: none;
      font-size: var(--font-size-72px);
      line-height: calc(88 / 72);
    }
  }

  &[data-has-gradient="true"] {
    margin-inline: auto;
    margin-bottom: 50px;
    background-image: linear-gradient(
      to right,
      #732c82,
      #282874 27%,
      #7a1439 51%,
      #ffcb04 71%,
      rgba(255, 203, 4, 0.9) 80%,
      #009edf
    );
    background-clip: text;
    font-size: var(--font-size-56px);
    line-height: 1.07;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;

    @media (width >= 767px) {
      margin-bottom: 45px;
      max-width: 705px;
      font-size: var(--font-size-88px);
      line-height: 1.09;
    }
  }
}

/* TODO
 * color: color-mix(
 *  in srgb,
 *   var(--page-theme-contrast-color, var(--color-text)),
 *   transparent 20%
 * ); */

.description p {
  margin-inline: auto;
  max-width: 270px; /* This is probably far to narrow... */
  color: var(--page-theme-contrast-color, var(--color-text-subtle));
  font-size: var(--font-size-16px);
  line-height: 1.5;
  text-align: center;

  @media (width >= 767px) {
    max-width: 694px;
    font-size: var(--font-size-24px);
    line-height: 1.5;
  }
}

.content {
  position: relative; /* For buttons positioning */
}

.embla__viewport {
  width: 100%;
}

.embla__container {
  display: flex;

  .container:is([data-slide-length="1"], [data-slide-length="2"]) & {
    display: flex;
    left: 0 !important;
    justify-content: center;
    transform: none !important;
  }

  .container:is([data-slide-length="3"]) & {
    display: flex;
    justify-content: center;
  }
}

.card {
  /**
   * ✝️ Using `clip-path` to animate the height of the card, because it doesn't 
   * change the geometry of the box, which makes it more performant than 
   * animating `height`+`margin`.
   */

  --_clip-block: 0;
  --_card-background: var(
    --card-background,
    var(--color-surface-solid-light-lighter)
  );
  --_card-color: var(--card-color, var(--color-text-heading));

  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  clip-path: inset(var(--_clip-block) 0 round var(--radius-large)); /* ✝️ */
  margin-inline: 5px;
  box-shadow: var(--elevation-2-above);
  border-radius: var(--radius-large);
  background-color: var(--_card-background);
  padding-inline: 19px;
  padding-block: 10px;
  height: 330px;
  color: var(--_card-color);
  text-align: center;
  text-decoration: none;

  @media (width >= 767px) {
    transition: clip-path 0.5s ease-in-out;
    width: 394px;
    height: 650px;

    /**
     * Deliberately not clipping inactive slides when there's only 2 of them,
     * because otherwise one is bigger than the other, so they just look 
     * lopsided.
     */
    .container:not(
        [data-slide-length="1"],
        [data-slide-length="2"],
        [data-slide-length="3"]
      )
      & {
      --_clip-block: 35px; /* Will be set to zero when the card is "selected" */
    }

    /**
     * Deliberately not clipping inactive slides when there's only 2 of them,
     * because otherwise one is bigger than the other, so they just look 
     * lopsided.
     */
    .container:not(
        [data-slide-length="1"],
        [data-slide-length="2"],
        [data-slide-length="3"]
      )
      [data-selected="true"]
      & {
      --_clip-block: 0;
    }
  }

  .container:is([data-slide-length="1"], [data-slide-length="2"]) & {
    position: relative !important;
    left: 0 !important;
  }
}

.dietary-mark-high-sugar {
  --high-sugar-mark-inset-block-start: 10px;
  --high-sugar-mark-inset-inline-end: 10px;
  --high-sugar-mark-width: 20px;

  @media (width >= 1000px) {
    --high-sugar-mark-inset-block-start: 15px;
    --high-sugar-mark-inset-inline-end: 15px;
    --high-sugar-mark-width: 55px;
  }
}

.dietary-mark-vegetarian {
  --vegetarian-mark-inset-block-end: 94px;
  --vegetarian-mark-inset-inline-end: 11px;
  --vegetarian-mark-width: 16px;

  @media (width >= 767px) {
    --vegetarian-mark-inset-block-end: 118px;
    --vegetarian-mark-inset-inline-end: 18px;
    --vegetarian-mark-width: 24px;
  }
}

.card-content {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  transition: transform 0.5s ease-in-out;

  @media (width >= 767px) {
    .container:not(
        [data-slide-length="1"],
        [data-slide-length="2"],
        [data-slide-length="3"]
      )
      & {
      transform: scale(0.82);
    }

    .container:not(
        [data-slide-length="1"],
        [data-slide-length="2"],
        [data-slide-length="3"]
      )
      [data-selected="true"]
      & {
      transform: scale(1);
    }
  }
}

.image {
  display: block;
  margin-bottom: 20px;
  height: 215px;

  @media (width >= 767px) {
    height: 453px;
  }

  &[data-with-stamp="true"] {
    position: relative;
    left: 15px;

    @media (width >= 767px) {
      left: 30px;
    }
  }
}

.product-label {
  /**
   * ✝️ The `.card-content` element needs to be vertically-centered within the 
   * card, but business have also requested that the tops of the cans are 
   * aligned, which means we need to ensure that the intrinsic height of 
   * `.card-content` is always the same, no matter whether the `.product-label`
   * wraps to 2 lines ("Red Bull Energy Drink") or 3 lines ("Red Bull Spring 
   * Edition Sugarfree"). Hence we're fixing the height of this label, to always
   * account for up to 3 lines. 
   */
  width: 120px;
  height: 3lh; /* ✝️ */
  color: inherit; /* inherit from card parent with CSS variable */
  font-weight: var(--font-weight-heading);
  font-size: var(--font-size-16px);
  line-height: 1.14;
  font-family: var(--font-family-heading);

  @media (width >= 767px) {
    width: 300px;
    font-size: var(--font-size-28px);
    line-height: 1.17;
  }

  &[data-is-white="true"] {
    color: var(--color-text-light);
  }
}

.product-name {
  display: block;
  width: 100%;
}

.embla {
  position: relative;
  margin-inline: auto;
  max-width: 100%;
  overflow-x: clip;

  .container:is(
      [data-slide-length="1"],
      [data-slide-length="2"],
      [data-slide-length="3"]
    )
    & {
    max-width: 1600px;
  }
}

.slider-button {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  z-index: 1;
  transition: opacity 0.25s ease-in;
  cursor: pointer;

  @media (width >= 767px) {
    display: block;

    .container:is(:hover, :focus-within) & {
      opacity: 1;
    }
  }

  @media (width >= 1290px) {
    .container:is(
        [data-slide-length="1"],
        [data-slide-length="2"],
        [data-slide-length="3"]
      )
      & {
      display: none;
    }
  }
}

[data-has-custom-theme] .slider-button {
  --cosmos-button-background: var(--theming-button-background);
  --cosmos-button-background-hover: var(--theming-button-background-hover);
  --cosmos-button-color: var(--theming-button-color);
  --cosmos-button-color-hover: var(--theming-button-color-hover);
  --cosmos-button-border-width: var(--theming-button-border-width);
  --cosmos-button-border-color: var(--theming-button-border-color);
  --cosmos-button-border-color-hover: var(--theming-button-border-color-hover);
}

@media (width >= 767px) {
  .slider-button--next {
    inset-inline-end: 20px;
  }

  .slider-button--prev {
    inset-inline-start: 20px;
  }
}

html[dir="rtl"] .slider-button--next,
html[dir="rtl"] .slider-button--prev {
  transform: scaleX(-1) translateY(-50%); /* Flipped for RTL */
}

.button-wrapper {
  display: flex;
  column-gap: var(--button-wrapper-column-gap);
  row-gap: var(--button-wrapper-row-gap);
  flex-wrap: wrap;
  justify-content: center;
  margin-top: var(--button-wrapper-block-start);
}

.button:not([kind="link"]) {
  --cosmos-button-min-width: var(--button-min-width);
}

.button[kind="primary"] {
  --cosmos-button-background: var(--theming-button-background);
  --cosmos-button-background-hover: var(--theming-button-background-hover);
  --cosmos-button-color: var(--theming-button-color);
  --cosmos-button-color-hover: var(--theming-button-color-hover);
  --cosmos-button-border-width: var(--theming-button-border-width);
  --cosmos-button-border-color: var(--theming-button-border-color);
  --cosmos-button-border-color-hover: var(--theming-button-border-color-hover);
}
